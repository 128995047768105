/*
 * Third party
 */


/*
 * Custom
 */

(function($) { $(function() { 

    // fullpage + parallax на баннере
    function fullpageInitAndDestroy(watch){
        var mql = window.matchMedia('all and (min-width: 991px)');
        if(!watch){
            if (mql.matches) {
                if($('#fullpage')){
                    $('#fullpage').fullpage({
                        //licenseKey: '4E25228B-7C8B4585-A791A885-52CF92B7',
                        autoScrolling: true,
                        showActiveTooltip: true,
                        anchors:['intro', 'about', 'services', 'for-advertisers', 'advantages', 'advantages-more', 'bloggers', 'contacts'],
                        menu: '#menu',
                        navigation: true,
                        navigationPosition: 'left',
                        //scrollOverflow: true
                        /*responsiveWidth: 991.9,
                        afterResponsive: function(isResponsive){
                            fullpage_api.destroy();
                        }*/
                    });
                }
                var scene = document.getElementById('scene');
                if (scene){
                    var parallaxInstance = new Parallax(scene);
                }
            } else {
                if($('#fullpage')){
                    $('#fullpage').fullpage({
                        //licenseKey: '4E25228B-7C8B4585-A791A885-52CF92B7',
                        //autoScrolling: false,
                        anchors:['intro', 'about', 'services', 'for-advertisers', 'advantages', 'advantages-more', 'bloggers', 'contacts'],
                        menu: '#menu',
                        navigation: false,
                        //normalScrollElements: '#section1, #section2, #section3, #section5, #sub-section1, #sub-section3',                      
                    });
                }
            }
        } else {
            if (mql.matches) {
                fullpage_api.reBuild();
            } else {
                fullpage_api.destroy('all');
            }
        }
    } 


    // Параллакс на картинках
    if(window.innerWidth > 992) {
        var advertisersScene = document.getElementById('advertisers-scene');
        var parallaxInstance = new Parallax(advertisersScene, {
            relativeInput: true
        });

        var blogers = document.getElementById('blogers-scene');
        var parallaxInstance = new Parallax(blogers, {
            relativeInput: true
        });
    };



    // Пузырьки
    bubbly({
        blur: 1,
        colorStart: '#1F2D43',
        colorStop: '#1F2D43',
        bubbles: 15,
        compose: "source-over",
        bubbleFunc:() => `hsl(215, 100%, 50%)`
    });



    // Кнопка бургера в мобильном
    $(".js-controller").fastmark({controlledclass: 'active'});
    fullpageInitAndDestroy(false);

    $('.menu a').click(function(e){

        $('.navmenu').removeClass('active');
        $('.bars').removeClass('active');

    });



    // Шапка при скролле
    $(window).scroll(function(){
        if($(window).scrollTop() > 50){
            $('.header').addClass('scrolled');
        }  else{
            $('.header').removeClass('scrolled');
        }
    });



    //$( window ).resize(function() {
    //     fullpageInitAndDestroy();
    //});



    // Переключение рус/анг версий    
    var checkbox = document.querySelector("#lang-switch");
    checkbox.addEventListener('change', function() {
        if (this.checked) {
            console.log("Checkbox is checked..");
            setTimeout(() => {  
                location.href = "index.html";
            }, 1000);
        } else {
            console.log("Checkbox is not checked..");
            setTimeout(() => {  
                location.href = "eng-intro.html";
            }, 1000);
        }
    });

}) })(jQuery)